import { useRuntimeConfig } from '#app'
import { combineUrlWithParams } from '~/utils/httpUtils'

const { useCustomFetch } = useFetchBase()

const base = '/api/message'
const module = '/conversationTags'
const path = base + module

// request(REQ) Api  --------------------------------
const getConversationTagsREQ = path + '/list/q' // 搜索對話標籤列表
const getAllConversationTagsREQ = path + '/list' // 獲取對話標籤列表
const addConversationTagREQ = path + '/add' // 添加對話標籤
const editConversationTagREQ = path + '/update' // 編輯對話標籤
const deleteConversationTagREQ = path + '/del' // 刪除對話標籤

// request(REQ) Function  --------------------------------

/**
 * 獲取對話標籤列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 分頁數據
 */
export const getAllConversationTagsFn = (params, callback) => {
  // check params.word not undefined
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getAllConversationTagsREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 搜索對話標籤列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 分頁數據
 */
export const getConversationTagsFn = (params, callback) => {
  // check params.word not undefined
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConversationTagsREQ, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 添加對話標籤
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const addConversationTagFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addConversationTagREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 編輯對話標籤
 * @param {Object} params 查詢參數
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
export const editConversationTagFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editConversationTagREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除對話標籤
 * @param {Object} params 查詢參數
 * @param {Object} callback 回掉函數
 * @returns
 */
export const deleteConversationTagFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteConversationTagREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}
